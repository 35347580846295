<template lang="pug">
.ios-pwa-install(v-if="iosPwaInstallPopup.open")
  .ios-pwa-install__card
    .ios-pwa-install__header
      .ios-pwa-install__title Installeer de Hofweb App
      .ios-pwa-install__cancel(@click="cancel") Annuleren
    .ios-pwa-install__content
      .ios-pwa-install__image
        img(:src="require('~/assets/images/leaf-loader.svg')")
      .ios-pwa-install__image-text Voeg de Hofweb app toe aan je iPhone
      .ios-pwa-install__separator
      .ios-pwa-install__steps
        .ios-pwa-install__step
          img.ios-pwa-install__step-icon(:src="require('~/assets/images/ios-share-icon.svg')")
          .ios-pwa-install__step-instruction 1&#41; Druk op 'Deel' knop op de menubalk.
        .ios-pwa-install__step
          img.ios-pwa-install__step-icon(:src="require('~/assets/images/ios-plus-icon.svg')")
          .ios-pwa-install__step-instruction 2&#41; Druk op 'Toevoegen aan startscherm'.
</template>

<script>
import { mapGetters } from 'vuex';
import { pwaMixin } from '@/mixins/pwaMixin';

export default {
  name: 'IosPwaInstall',
  mixins: [pwaMixin],
  computed: {
    ...mapGetters({
      iosPwaInstallPopup: 'shared/iosPwaInstallPopup',
    }),
  },
  mounted() {
    if (this.isPWA || !this.isIOS) return;

    if (localStorage.getItem('iosPwaInstallPopupDismissed') === 'true') {
      this.$store.commit('shared/setIosPwaInstallPopup', { open: false, dismissed: true });
    } else {
      this.$store.commit('shared/setIosPwaInstallPopup', { open: true, dismissed: false });
    }
  },
  methods: {
    cancel() {
      this.$store.commit('shared/setIosPwaInstallPopup', { open: false, dismissed: true });
    },
  },
};
</script>

<style lang="scss">
.ios-pwa-install {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background: rgba($black, .5);
  display: flex;
  align-items: flex-end;

  &__card {
    width: 100%;
    background: $platinum;
    margin: 10px;
    border-radius: 10px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid $darkPlatinum;
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
  }

  &__cancel {
    color: $chefchaouenBlue;
    font-size: 15px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 15px 30px 15px;
  }

  &__image {
    width: 60px;
    height: 60px;
    background: $white;
    border-radius: 10px;
    padding: 10px;
    overflow: hidden;
    box-sizing: border-box;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__image-text {
    margin: 15px 0 0 0;
    color: $battleshipGray;
  }

  &__separator {
    height: 1px;
    width: 100%;
    background: $darkPlatinum;
    margin: 15px 0;
  }

  &__steps {
    width: calc(100% - 30px);
  }

  &__step {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin: 0 0 15px 0;
    }

    &-icon {
      margin: 0 15px 0 0;
      width: 22px;
    }

    &-instruction {
      color: $battleshipGray;
      font-weight: 500;
    }
  }
}
</style>
